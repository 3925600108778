@use "sass:math";

@mixin media-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin media-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin mobile-only {
  @include media-max(768px) {
    @content;
  }
}
@mixin medium {
  @include media-min(769px) {
    @content;
  }
}
@mixin large {
  @include media-min(1025px) {
    @content;
  }
}
@mixin extraLarge {
  @include media-min(1301px) {
    @content;
  }
}
$BOX_SHADOW: 0 0 0.1px 2px white, 0 0 40.1px 2px rgba(192 219 255 / 48%),
  0 0 22.35px 2px rgb(65 120 255 / 24%);
$BOX_SHADOW_SMALL: 0 0 0.1px 2px white, 0 0 15.1px 2px rgba(192 219 255 / 48%),
  0 0 11.1px 2px rgb(65 120 255 / 24%);
$WHITE_BG: hsl(228, 9%, 89%);
$BLACK_BG: hsl(218, 10%, 11%);
$ACCENT_COLOR: hsl(223, 67%, 92%); // hsl(174deg 57% 93%); //
@mixin glow-box-shadow($is_on_hover: false) {
  overflow: hidden;
  border-radius: 5px;
  @if $is_on_hover {
    box-shadow: 0 0 0.1px 2px white;
    &:hover,
    &:focus-within {
      box-shadow: #{$BOX_SHADOW_SMALL};
      @include medium {
        box-shadow: #{$BOX_SHADOW};
      }
    }
  } @else {
    box-shadow: #{$BOX_SHADOW_SMALL};
    @include medium {
      box-shadow: #{$BOX_SHADOW};
    }
  }
  &.off {
    box-shadow: 0 0 0.1px 2px white;
  }
}

@mixin glow($is_on_hover: false) {
  .glow {
    @include glow-box-shadow($is_on_hover);
  }
}

@mixin content(
  $selector: "main",
  $medium: 768px,
  $large: 1024px,
  $extra-large: 1300px
) {
  @media screen and (min-width: $medium) {
    #{$selector} {
      max-width: $medium - 28px;
    }
  }
  @if $large > $medium {
    @media screen and (min-width: $large) {
      #{$selector} {
        max-width: $large - 36px;
      }
    }
  }
  @if $extra-large > $large {
    @media screen and (min-width: $extra-large) {
      #{$selector} {
        max-width: $extra-large - 48px;
      }
    }
  }
  #{$selector} {
    width: 90%;
    z-index: 0;
    position: relative;
    margin: auto;
    // background-color: rgba(255 0 0 /0.2);
    padding-bottom: 42px;
    @content;
  }
}

@mixin bottom_text {
  .bottom_text {
    position: relative;
    span:first-child {
      opacity: 0.7;
      font-size: 0.7em;
      position: absolute;
      bottom: -1.4ch;
      display: inline-flex;
      align-items: center;
    }
    @content;
  }
}

@mixin top_text {
  .top_text {
    position: relative;
    span:first-child {
      opacity: 0.7;
      font-size: 0.7em;
      position: absolute;
      top: -1.4ch;
      display: inline-flex;
      align-items: center;
      @content;
    }
  }
}

@mixin bigger_first_letter($selector: h2, $FONT_SIZE_BASE: 1em) {
  #{$selector} {
    display: inline-block;
    font-size: $FONT_SIZE_BASE;
    & + #{$selector} {
      margin-left: 1ch;
    }
    &::first-letter {
      font-size: 1.25em;
    }
  }
}
