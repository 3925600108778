@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap");
@import "mixins";
.slick-initialized .slick-slide.slick-active {
  z-index: 1;
}
* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
#label {
  all: revert;
  font-family: "Inter", sans-serif;
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  -webkit-letter-spacing: 0.01em;
  -moz-letter-spacing: 0.01em;
  -ms-letter-spacing: 0.01em;
  letter-spacing: 0.01em;
  line-height: 1.35;
  color: var(--tina-color-grey-8);
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 480px) {
  html,
  body {
    --tina-sidebar-width: 440px;
  }
}
html,
body {
  font-size: 18px;
  color: white;
  background-color: $BLACK_BG;
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
  scroll-behavior: smooth;
}
h1,
h2,
h3 {
  font-family: "Bebas Neue Pro Expanded";
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  // text-shadow: 0 0 20px rgb(192 219 255 / 28%), 0 0 11px rgb(65 120 255 / 14%);
}

a {
  color: currentColor;
  text-decoration: none;
  cursor: pointer;
}

@include content;

p a {
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

input,
textarea {
  border: none;
  font-family: "Open Sans";
  margin: 0.5em 0;
  border-radius: 4px;
  padding: 0.4em 0.4em;
  outline: none;
  font-size: 0.85em;
  // @include glow-box-shadow(true);
}
.simplebar-scrollbar::before {
  background-color: white;
}
